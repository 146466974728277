import React from "react";
import {Provider} from "react-redux";
import '../../App.css'
import SetCursor from "../containers/common/SetCursor";
import AppRouter from "../routes/AppRoutes";
import Preloader from "../containers/preloader/Preloader";

export const HOME = '/';

const App = ({ store }) => (
    <Provider store={store}>
        {/*<Preloader>*/}
        {/*    <SetCursor/>*/}
            <AppRouter/>
        {/*</Preloader>*/}
    </Provider>
)
export default (App);