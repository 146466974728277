import mtf1 from '../../../../assets/motifs/mtf1.png';
import mtf2 from '../../../../assets/motifs/mtf2.png';
import mtf4 from '../../../../assets/motifs/mtf4.png';
import mtf5 from '../../../../assets/motifs/mtf5.png';
import mtf7 from '../../../../assets/motifs/mtf7.png';
import mtf8 from '../../../../assets/motifs/mtf8.png';
import mtf9 from '../../../../assets/motifs/mtf9.png';
import mtf10 from '../../../../assets/motifs/mtf10.png';
import mtf11 from '../../../../assets/motifs/mtf11.png';

const motifs = [
    {src: mtf11,x:0,y:0},
    {src: mtf2,x:0,y:0},
    {src: mtf4,x:0,y:0},
    {src: mtf5,x:0,y:0},
    {src: mtf7,x:0,y:0},
    {src: mtf8,x:0,y:0},
    {src: mtf9,x:0,y:0},
    {src: mtf1,x:0,y:0},
    {src: mtf10,x:0,y:0},
];

export default motifs;