import React, {useEffect} from "react";
import {connect} from "react-redux";
import importAll from "../../util/importAll";
import preloadAll, {preload} from "../../util/preload";
import {preloaded} from "../../actions/UIActions";
import { useLocation } from 'react-router-dom';
import PreloaderAnimation from "./PreloaderAnimation";
import Grain from "../common/Grain";
import preloaderImage from "../../../assets/preloader-img.png";
import preloaderImages from "./preloaderImages";

const Preloader = (props) => {
    const location = useLocation();
    useEffect(() => {
        const preloadImages = async () => {
            const currentImgs = (preloaderImages(location.pathname));
            const allImgs = importAll(
                require.context(
                    "../../../assets/",
                    true,
                    /\.(png|jpe?g)$/)
            )
            await preload(preloaderImage);
            await preloadAll(currentImgs);
            props.dispatch(preloaded());
            preloadAll(allImgs);
        };
        preloadImages();
    }, []);

    return (
        <>
            {
                props.preloaded &&
                props.children
            }
            {
                !props.preloaded &&
                (<div className={'preloader'}>
                    {/*<PreloaderAnimation/>*/}
                    <div className={'preloader-text'}>
                        ASHISH AVIKUNTHAK
                    </div>
                    <Grain/>
                </div>)
            }
        </>
    );
}

function mapStateToProps(state) {
    return {
        preloaded: state.uiReducer.preloaded
    };
}

export default connect(mapStateToProps)(Preloader);