import imgSrc1 from '../../../assets/film-images/Feature Films Images/1 Nirakar  Chhaya/6 Nirakar Chhayya.jpg';
import imgSrc2 from '../../../assets/film-images/Feature Films Images/2 Katho Upanishd/Upanishad Publicity Still 3.jpg';
import imgSrc3 from '../../../assets/film-images/Feature Films Images/3 Rati Chakravyuh/2 Rati Chakravyuh.jpg';
import imgSrc4 from '../../../assets/film-images/Feature Films Images/4 Kalkimanthankatha/6 Kalkimanthankatha Publicity Photo.jpg';
import imgSrc5 from '../../../assets/film-images/Feature Films Images/5 Aapothkalin Trikalika/3 The Emergency of Kali copy.jpg';
import imgSrc6 from '../../../assets/film-images/Feature Films Images/6 Vrindavani Vairagya/Vrindavani Publicity Still 7.jpg';
import imgSrc7 from '../../../assets/film-images/Feature Films Images/7 Namanush Premer Kothamala/Glossary of Non Human Love 5.jpg';
import imgSrc8 from '../../../assets/film-images/Feature Films Images/8 Vidhvastha/Vidhvastha Publicity Still 17.jpg';
import pressStrip from '../../../assets/press/film_strip2.png';
import importAll from "../../util/importAll";

import featureFilmsList from "../films/featureFilms/feature-films-list";
import shortFilmsList from "../films/shortFilms/short-films-list";

const urls = [
    {
        title:'Home',
        href:'/',
        images: [imgSrc1,imgSrc2,imgSrc3,imgSrc4,imgSrc5,imgSrc6,imgSrc7,imgSrc8],
    },

    {
        title:'Feature Films',
        href:'/feature-films',
        images: (importAll(
            require.context(
                "../../../assets/film-images/Feature Films Images/",
                true,
                /\.(png|jpe?g)$/)
        ))
    },
    {
        title:'Short Films',
        href:'/short-films',
        images: (importAll(
            require.context(
                "../../../assets/film-images/Short Films Images/",
                true,
                /\.(png|jpe?g)$/)
        ))
    },
    {
        title:'Writings',
        href:'/writings',
        images: pressStrip
    },
    {
        title:'Press',
        href:'/press',
        images: pressStrip
    },
    {
        title:'Catalog',
        href:'/catalog',
        images: (importAll(
            require.context(
                "../../../assets/catalog/thumbnails/",
                true,
                /\.(png|jpe?g)$/)
        ))
    },
    {
        title:'Interviews',
        href:'/interviews',
        images: []
    },
    {
        title:'About',
        href:'/about',
        images: (importAll(
            require.context(
                "../../../assets/about/",
                true,
                /\.(png|jpe?g)$/)
        ))
    }
];

const preloaderImages = (location) => {

    //check main pages
    for(let item of urls) {
        if (item.href === location) {
            return(item.images);
        }
    }

    //check sub pages
    const filmsList = [...shortFilmsList,...featureFilmsList];
    const filmId = decodeURI(location.split("/")[2]);
    const title = filmId.replaceAll("-"," ").replace("—","/");
    const filmObject = (filmsList.find((elem) => elem.title === title) || {})["details"] || {};
    return filmObject.images;

}

export default preloaderImages;