import {CURSOR_HIDE, CURSOR_MODE, CURSOR_SIZE, IMAGE_MODE, TEXT_MODE} from "../constants/cursorConstants";

export function changeCursorSize(size) {
    return {
        type: CURSOR_SIZE,
        cursorSize: size
    }
}

export function hideCursor(hideCursor) {
    return {
        type: CURSOR_HIDE,
        cursorHide: hideCursor
    }
}

export function showCursorText(text) {
    return {
        type: TEXT_MODE,
        text: text,
        mode: TEXT_MODE
    }
}

export function showCursorImage(img) {
    return {
        type: IMAGE_MODE,
        img: img,
        mode: IMAGE_MODE
    }
}